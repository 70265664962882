import React, {  useEffect } from 'react'

import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../shared/utils/toaster'
import {
	DocHeader,
	TypeDoc
} from '../../modules/PointProgramChallengeDetails/types'
import { UploadSpreadsheetView } from './upload-spreadsheet.view'

export interface UploadSpreadSheetProps {
	clearPdvsFile: () => void
	handleFilter: () => void
	haveCSV: boolean
	selectedPDVCSV: string[]
	setHaveCSV: React.Dispatch<React.SetStateAction<boolean>>
	setSelectedPDVCSV: React.Dispatch<React.SetStateAction<string[]>>
	onCsvUpload: (pdvs: string[]) => void
}

export const UploadSpreadsheet = ({
	clearPdvsFile,
	handleFilter,
	haveCSV,
	selectedPDVCSV,
	setHaveCSV,
	setSelectedPDVCSV,
	onCsvUpload,
}: UploadSpreadSheetProps) => {
	async function loadedCsvReader(data: any[], fileInfo: any) {
		setSelectedPDVCSV([])
		if (fileInfo.size > 1024 * 1024 * 70) {
			cogoToast.error(
				'Seu arquivo é maior que 70MB, tente com um arquivo de tamanho até 70MB!',
				cogoDefaultOptions
			)
			return
		}
		const createPDVCSVOption = (value: string[]) => {
			setSelectedPDVCSV((prevState) => [...prevState, ...value])
			onCsvUpload(value)
		}
		if (fileInfo.type === TypeDoc.TEXTCSV) {
			if (Object.keys(data[0]).length > 1) {
				cogoToast.warn(
					'O formato do seu arquivo está incorreto. Por favor, consulte o arquivo de template e preencha os dados nas colunas correspondentes.',
					cogoDefaultOptions
				)
				setHaveCSV(false)
				return
			}

			if (Object.keys(data[0])[0] === DocHeader.PDV) {
				const normalizedPdvs = [] as string[]
				try {
					data.map((item: any) =>
						item?.pdv ? normalizedPdvs.push(item.pdv) : ''
					)
					createPDVCSVOption([...new Set(normalizedPdvs)])
				} finally {
					cogoToast.success(
						'Planilha reconhecida com sucesso!',
						cogoDefaultOptions
					)
					setHaveCSV(true)
				}
				return
			} else {
				cogoToast.error(
					'Planilha não tem a coluna "PDV"!',
					cogoDefaultOptions
				)
				setHaveCSV(false)
				return
			}
		} else {
			cogoToast.error(
				'O tipo de arquivo deve ser .csv!',
				cogoDefaultOptions
			)
			setHaveCSV(false)
			return
		}
	}

	function errorOnCsvReader() {
		cogoToast.error(
			'Erro inesperado na leitura do arquivo CSV, tente novamente com outro arquivo .csv!',
			cogoDefaultOptions
		)
		setHaveCSV(false)
	}

	const parseOptions = {
		header: true,
		dynamicTyping: false,
		skipEmptyLines: true,
		transformHeader: (header: any) =>
			String(header).toLowerCase().replace(/\W/g, '_')
	}

	useEffect(() => {
		if (selectedPDVCSV.length === 0) setHaveCSV(false)
		else {
			setHaveCSV(true)
			handleFilter()
		}
	}, [haveCSV, handleFilter, selectedPDVCSV.length])

	return (
		<UploadSpreadsheetView
			parserOptions={parseOptions}
			errorOnCsvReader={errorOnCsvReader}
			onClick={clearPdvsFile}
			haveCSV={haveCSV}
			loadedCsvReader={loadedCsvReader}
		/>
	)
}
