import { IProduct } from './types'

class RegisterProductMapper {
	toPersistence(domain: IProduct): IProduct {
		return {
			MaterialID: domain.MaterialID ?? '',
			Description: domain.Description ?? '',
			UnitMeasureSale: domain.UnitMeasureSale ?? '',
			SiteDescription: domain.SiteDescription ?? '',
			ImageUrl: domain.ImageUrl ?? '',
			PackageImageUrl: domain.PackageImageUrl ?? '',
			Category: domain.Category ?? '',
			Brand: domain.Brand ?? '',
			Package: domain.Package ?? '',
			PackageType: domain.PackageType ?? '',
			Family: domain.Family ?? '',
			Style: domain.Style ?? '',
			Coloration: domain.Coloration ?? '',
			AlcoholContent: domain.AlcoholContent ?? '',
			UnitBarcode: domain.UnitBarcode ?? '',
			PackageBarcode: domain.PackageBarcode ?? '',
			IBU: domain.IBU ?? '',
			LogicalDelete: domain.LogicalDelete ?? '',
			ScoreValue: domain.ScoreValue ?? '',
			ScoreRate: domain.ScoreRate ?? '',
			HasChannelCustom: domain.HasChannelCustom ?? false,
			categoryId: domain.categoryId ?? '',
			listingPosition: domain.listingPosition,
			showInBonification: domain.showInBonification
		}
	}
	toPersistenceEdit(domain: IProduct): IProduct {
		return {
			ID: domain.ID ?? '',
			MaterialID: domain.MaterialID ?? '',
			Description: domain.Description ?? '',
			UnitMeasureSale: domain.UnitMeasureSale ?? '',
			SiteDescription: domain.SiteDescription ?? '',
			ImageUrl: domain.ImageUrl ?? '',
			PackageImageUrl: domain.PackageImageUrl ?? '',
			Category: domain.Category ?? '',
			Brand: domain.Brand ?? '',
			Package: domain.Package ?? '',
			PackageType: domain.PackageType ?? '',
			Family: domain.Family ?? '',
			Style: domain.Style ?? '',
			Coloration: domain.Coloration ?? '',
			AlcoholContent: domain.AlcoholContent ?? '',
			UnitBarcode: domain.UnitBarcode ?? '',
			PackageBarcode: domain.PackageBarcode ?? '',
			IBU: domain.IBU ?? '',
			LogicalDelete: domain.LogicalDelete ?? '',
			ScoreValue: domain.ScoreValue ?? '',
			ScoreRate: domain.ScoreRate ?? '',
			HasChannelCustom: domain.HasChannelCustom ?? false,
			categoryId: domain.categoryId ?? '',
			listingPosition: domain.listingPosition,
			showInBonification: domain.showInBonification
		}
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new RegisterProductMapper()
