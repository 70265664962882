export enum AdminRoutesEnum {
	RESALE_PARAMETERS = '/resale-parameters',
	RESALE_LIST = '/resale-list',
	RESALE_PRODUCTS = '/resale-products',
	REGISTER_PARAM = '/register-param',
	CANCELLATION_REQUESTS = '/sac/cancellation-requests',
	SAC_PENDING_APPROVAL = '/sac/pending-approval',
	REGISTER_PRODUCT = '/register-product',
	PUSH_NOTIFICATION = '/push-notification',
	PUSH_NOTIFICATION__HISTORY_PUSH = '/history-push',
	PUSH_NOTIFICATION__TRANSACTIONS_PUSH = '/push-transactions',
	USERS = '/users',
	CONSULT_CUSTOMER = '/consult-client',
	CONSULT_CUSTOMER_INFO = '/consult-client-info',
	CUSTOMER_EXCLUSION = '/customer-exclusion',
	CUSTOMER_EXCLUSION_BULK_STATUS = '/customer-exclusion-bulk-status',
	CUSTOMER_EXCLUSION_BULK_STATUS_DETAILS = '/customer-exclusion-bulk-status-details',
	BANNERS_MANAGEMENT = '/banners-management',
	COMBOS = '/combos',
	POINTS_PROGRAM__RULES__POINT_GAIN_RATIO = '/points-program/rules/point-gain-ratio',
	POINTS_PROGRAM__RULES__PRODUCT_REDEMPTION = '/points-program/rules/product-redemption',
	POINTS_PROGRAM__RULES__DETAIL = '/points-program/rules/detail/:rule_type',
	POINTS_PROGRAM__RULES__POINT_GAIN_RATIO__DETAIL = '/points-program/rules/detail/point-gain-ratio',
	POINTS_PROGRAM__RULES__PRODUCT_REDEMPTION__DETAIL = '/points-program/rules/detail/product-redemption',
	POINTS_PROGRAM__CHALLENGES = '/points-program/challenges',
	POINTS_PROGRAM__CHALLENGE_DETAIL = '/points-program/detail/challenge',
	CLIENTS_REPORT = '/clients_report',
	ODER = '/order',
	REGISTER_CATEGORY = '/register-category',
	DELIVERY_MANAGEMENT = '/delivery-management',
	SUGGESTED_ORDERS = '/suggested-orders',
	CREATE_COUPON = '/coupon/create-coupon',
	HISTORY_COUPON = '/coupon/history-coupon'
}
