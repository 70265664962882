import { ICategoriesPosition } from '../interfaces/categories'
import { apiAdmin, apiCustomer } from './axiosInstace'

export const categoriesServices = {
	getCategoriesAdmin: async function (): Promise<any> {
		const url = `/categories`
		const { data } = await apiAdmin.get(url)
		return data
	},

	getCategoriesCustomer: async function (): Promise<any> {
		const url = `/categories`
		const { data } = await apiCustomer.get(url)
		return data
	},

	getProductsByCategorie: async function ({
		ID
	}: {
		ID: string
	}): Promise<any> {
		const url = `/categories/${ID}/products`
		const { data } = await apiAdmin.get(url)
		return data
	},

	updateCategoriesList: async function (
		categoriesList: ICategoriesPosition[]
	): Promise<any> {
		// console.log(categoriesList)
		const { data } = await apiAdmin.patch(`/categories`, categoriesList)
		return data
	}
}
