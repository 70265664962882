export const productItems = (items: any) => {
	return items.map((item: any) => ({
		name: item.Description,
		value: item.MaterialID,
		...item
	}))
}

export const addNameValueToCategory = (arrayCategory: any) => {
	const finalArray = arrayCategory.map((categorie: any) => {
		return {
			name: categorie.description,
			value: categorie.ID,
			...categorie
		}
	})
	return finalArray
}

export const categoryItems = (items: any) => {
	const categorys = items.map((item: any) => item.Category).filter(Boolean)
	const newCategorys = [...new Set(categorys)]
	const newArray = newCategorys.map((category: any) => ({
		name:
			category.toLowerCase()[0].toUpperCase() +
			category.toLowerCase().substring(1),
		value: category,
		Category: category
	}))
	return newArray
}

export const comboItems = (items: any) => {
	return items.map((item: any) => ({
		name: item.Description,
		value: item.MaterialID,
		...item
	}))
}
