import { IMaterial } from '../../../shared/interfaces/material'
import { ICategories } from '../../../shared/interfaces/categories'

export function finalOrderedListCategory({ array }: { array: ICategories[] }) {
	return array.map((item, index) => ({
		listingPosition: index,
		ID: item.ID
	}))
}

export function finalOrderedListMaterial({ array }: { array: IMaterial[] }) {
	return array.map((item, index) => ({
		listingPosition: index,
		ID: item.ID
	}))
}
