import React from 'react'
import { UploadSpreadsheetViewProps } from './upload-spreadsheet.types'
import * as S from './upload-spreadsheet.styles'
import ButtonDefault from '../buttons/button/button-default'
import CSVReader from 'react-csv-reader'
import { TypeButtonEnum } from '../buttons/button/button-default.types'

export const UploadSpreadsheetView = ({
	haveCSV,
	parserOptions,
	errorOnCsvReader,
	onClick,
	loadedCsvReader
}: UploadSpreadsheetViewProps) => {
	return (
		<S.Container>
			
				{haveCSV ? (
					<ButtonDefault
						buttonText={'REMOVER PLANILHA EXCEL -'}
						idElement="subir-planilha"
						onClick={onClick}
						fontSize="10px"
						width="auto"
						height="54px"
						border="1px #D8D2D3 solid"
						type={TypeButtonEnum.OUTLINED}
					/>
				) : (
					<CSVReader
						onFileLoaded={(data, file) => {
							loadedCsvReader(data, file)
						}}
						label={'SUBIR PLANILHA EXCEL +'}
						onError={errorOnCsvReader}
						inputId="subirPlanilhaExcel"
						parserOptions={parserOptions}
						cssClass="upload-csv"
						cssLabelClass="upload-csv-label"
					/>
				)}
			
		</S.Container>
	)
}
