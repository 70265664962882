import { apiAdmin } from '../../../../shared/services/axiosInstace'

import productsMapper from './mappers/products-mapper'
import {
	IGetProducts,
	IProductRequestProps,
	IProductResponseProps
} from './types/products.types'

class Products {
	async getProducts({
		brands,
		categoriesID,
		packages
	}: IProductRequestProps): Promise<IGetProducts> {
		const params: Record<string, string> = {
			categoryID: categoriesID.join(','),
			brand: encodeURIComponent(brands.join(',')),
			packaging: encodeURIComponent(packages.join(','))
		}

		Object.keys(params).forEach(
			(key) => params[key] === '' && delete params[key]
		)

		const { data } = await apiAdmin.get<IProductResponseProps[]>(
			`categories/products`,
			{
				params
			}
		)
		return productsMapper.toDomainProducts(data, categoriesID)
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new Products()
