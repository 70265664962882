import * as React from 'react'
import { Box } from '@mui/material'

import GPLogo from '../../../../assets/images/logo-bp-red.svg'
import { HeaderProps } from './header-login.types'
import * as S from './header-login.styles'
import { fnOpenChat } from '../../../../layout/footer-layout/utils/open-chat'
import { UnauthenticatedRoutesEnum } from '../../../../modules/Routes/unauthenticatedRoutesEnum'
import { useHistory } from 'react-router-dom'

const HeaderLogin = ({ openAccountSidebar }: HeaderProps) => {
	const history = useHistory()
	return (
		<S.HeaderWrapper component="div">
			<Box className="logo-wapper">
				<img
					src={GPLogo}
					alt="logo-gp"
					onClick={() =>
						history.replace(UnauthenticatedRoutesEnum.LOGIN)
					}
				/>
				<button className="help-text" onClick={() => fnOpenChat()}>
					Dúvidas
				</button>
			</Box>
			<button className="button-login" onClick={openAccountSidebar}>
				Entrar na minha conta
			</button>
		</S.HeaderWrapper>
	)
}

export default HeaderLogin
