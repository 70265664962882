import React, { useState, useEffect } from 'react'
import HeaderLogin from './components/header-login/header-login.component'
import { Box } from '@mui/material'
import * as S from './login-customer.styles'
import ButtonDefault from '../../components/buttons/button/button-default'
import { TypeButtonEnum } from '../../components/buttons/button/button-default.types'
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined'
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined'
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined'
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined'
import Sidebar from './components/sidebar/sidebar.component'
import { sidebarTypes } from './components/sidebar/sidebar.types'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import { hidePartOfDoc } from './utils/format-user-doc'
import { formatCnpjCpf } from '../../shared/utils/form'
import ImageSlider from './components/image-slider/image-slider.component'
import {
	PagesBannersEnum,
	fetchGetBannersLogin
} from '../../shared/services/banner.service'
import { useHistory } from 'react-router-dom'
import { CustomerRoutesEnum } from '../../modules/Routes/customerRoutesEnum'
import { CheckIsLogged } from '../../utils/check-is-logged'
import LoginLoading from './components/login-loading/login-loading.component'

const LoginCustomer = () => {
	const history = useHistory()
	const [isOpen, setIsOpen] = useState(false)
	const [sideBarContent, setSidebarContent] = useState<sidebarTypes>('')
	const [isLoggedUser, setIsLoggedUser] = useState(false)
	const [userAlreadyLoggedDoc, setUserAlreadyLogged] = useState({
		documentNumber: '',
		customerName: ''
	})

	const [banners, setBanners] = React.useState<any>([])
	const [isLoading, setIsLoading] = React.useState(false)

	React.useEffect(() => {
		// Verificando usuário logado
		const isLogged = CheckIsLogged()
		if (isLogged) {
			history.replace(CustomerRoutesEnum.PRODUCT_LIST)
		}
	}, [history])

	useEffect(() => {
		const loggedUserDataString = localStorage.getItem('@loggedUserData')
		if (loggedUserDataString !== null) {
			if (
				!isLoggedUser &&
				userAlreadyLoggedDoc.customerName.length === 0
			) {
				setIsLoggedUser(true)

				const loggedUserData = JSON.parse(loggedUserDataString)

				setUserAlreadyLogged({
					documentNumber: loggedUserData.docNumber,
					customerName: loggedUserData.customerName
				})
			}
		}
	}, [userAlreadyLoggedDoc, isLoggedUser])

	// Removendo usuário do localStorage
	const handleRemoveUserLogged = () => {
		// Remove o item do localStorage
		localStorage.removeItem('@loggedUserData')

		// Recarrega a página
		window.location.reload()
	}

	React.useEffect(() => {
		const handleGetBanners = async () => {
			setIsLoading(true)
			try {
				const bannersData = await fetchGetBannersLogin(
					PagesBannersEnum.LOGIN
				).finally(() => {
					setIsLoading(false)
				})
				setBanners(bannersData)
			} catch (error) {
				console.error(error)
			}
		}

		handleGetBanners()
	}, [])

	return (
		<>
			{isLoading ? (
				<LoginLoading />
			) : (
				<Box>
					<HeaderLogin
						openAccountSidebar={() => {
							setSidebarContent('login')
							setIsOpen(true)
						}}
					/>
					<Sidebar
						isOpen={isOpen}
						toggleSidebar={() => setIsOpen(false)}
						sideBarType={sideBarContent}
						handleChangeTypeSidebar={() =>
							setSidebarContent('firstAcess')
						}
					/>
					<S.MainWrapper>
						{banners.length === 0 && (
							<div className="animated-background"></div>
						)}
						{banners.length > 0 && (
							<ImageSlider sliderItens={banners} />
						)}
						<S.RightContainer>
							{isLoggedUser ? (
								<S.UserLogged>
									<h2>
										Entre em sua conta com a senha
										cadastrada
									</h2>
									<div className="wrapper-data-user">
										<div className="cicrleIcon">
											<PersonOutlineOutlinedIcon />
										</div>
										<div className="user-data">
											<h1>
												{
													userAlreadyLoggedDoc.customerName
												}
											</h1>
											<p>
												{hidePartOfDoc(
													formatCnpjCpf(
														userAlreadyLoggedDoc.documentNumber
													)
												)}
											</p>
										</div>
									</div>

									<ButtonDefault
										onClick={() => {
											setSidebarContent('login')
											setIsOpen(true)
										}}
										buttonText={'Senha'}
										type={TypeButtonEnum.PRIMARY}
										idElement="continueToPassword"
									/>
									<ButtonDefault
										onClick={() => handleRemoveUserLogged()}
										buttonText={'Sair'}
										type={TypeButtonEnum.OUTLINED}
										idElement="removeKeepLoged"
									/>
								</S.UserLogged>
							) : (
								<>
									<h1 className="title">
										Seu primeiro acesso?
									</h1>
									<ButtonDefault
										onClick={() => {
											setSidebarContent('firstAcess')
											setIsOpen(true)
										}}
										buttonText={'Criar conta'}
										type={TypeButtonEnum.PRIMARY}
										idElement="continueToFirstAcess"
									/>
									<h2 className="title">
										Benefícios em comprar com a<br /> gente
									</h2>

									<Box className="benefits-container">
										<Box className="benefit-item">
											<VerifiedOutlinedIcon />{' '}
											<p>
												<strong>Tenha</strong> as
												melhores ofertas e novidades
											</p>
										</Box>

										<Box className="benefit-item">
											<FmdGoodOutlinedIcon />{' '}
											<p>
												<strong>Receba</strong> seus
												pedidos de onde você estive
											</p>
										</Box>

										<Box className="benefit-item">
											<LocalShippingOutlinedIcon />{' '}
											<p>
												<strong>Acompanhe</strong> suas
												entregas e faturas
											</p>
										</Box>

										<Box className="benefit-item">
											<CurrencyExchangeOutlinedIcon />{' '}
											<p>
												<strong>Ganhe</strong> pontos
												por compra e troque por produtos
											</p>
										</Box>
									</Box>
								</>
							)}
						</S.RightContainer>
					</S.MainWrapper>
				</Box>
			)}
		</>
	)
}

export default LoginCustomer
