import { createElement } from 'react'

import { UserTypeEnum } from '../../shared/interfaces/customer'

import {
	PushNotification,
	RegisterParameter,
	RegisterProduct,
	ResaleList,
	ResaleParameters,
	ResaleProducts,
	CancellationRequests,
	PendingApproval,
	Users,
	CustomerExclusion,
	ProcessStatusList,
	ProcessStatusListDetails,
	PointProgramRuleDetails,
	PointsEarnedRatio,
	ProductRedemptionRules,
	ClientsReport,
	PointsProgramListOfChallenges,
	PointProgramChallengeDetails
} from '../index'

import { AdminRoutesEnum } from './adminRoutesEnum'
import { IRouteDataV2 } from './types'
import Combos from '../../views/Combos/combos.view'
import BannersManagement from '../../views/banners-management'
import HistoryPush from '../../views/push-notifications-admin/history-push/history-push.view'
import TransactionalPush from '../../views/push-notifications-admin/transactional-push/transactional-push.view'
import OrderAdmin from '../../views/order-admin/order-admin.view'
import ConsultCustomer from '../../views/consult-client/consult-client.view'
import ConsultCustomerInfo from '../../views/consult-client/consul-client-info/consult-client-info.view'
import DeliveryManagementAdmin from '../../views/delivery-management-admin/delivery-management.view'
import SuggestedOrdersAdmin from '../../views/suggested-orders-admin/suggested-orders-admin.view'
import CreateCoupon from '../../views/discount-coupon-admin/create-coupon/create-coupon.view'
import HistoryCoupon from '../../views/discount-coupon-admin/history-coupon/history-coupon.view'
// import RegisterCategory from '../../views/register-category/register-category.view'

export const adminRoutesData: IRouteDataV2[] = [
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Relatório de Cliente (Vendedor Virtual)',
		key: AdminRoutesEnum.CLIENTS_REPORT,
		path: AdminRoutesEnum.CLIENTS_REPORT,
		children: createElement(ClientsReport),
		routeType: 'CUSTOMERS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Parâmetros por Unidade',
		key: AdminRoutesEnum.RESALE_LIST,
		path: AdminRoutesEnum.RESALE_LIST,
		children: createElement(ResaleList),
		routeType: 'PARAMETERS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Parâmetros da Revenda',
		key: AdminRoutesEnum.RESALE_PARAMETERS,
		path: AdminRoutesEnum.RESALE_PARAMETERS,
		children: createElement(ResaleParameters),
		routeType: 'PARAMETERS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN, UserTypeEnum.PRICING],
		displayOnNavBar: true,
		name: 'Preços por Unidade',
		key: AdminRoutesEnum.RESALE_PRODUCTS,
		path: AdminRoutesEnum.RESALE_PRODUCTS,
		children: createElement(ResaleProducts),
		routeType: 'CD'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Parâmetros Gerais',
		key: AdminRoutesEnum.REGISTER_PARAM,
		path: AdminRoutesEnum.REGISTER_PARAM,
		children: createElement(RegisterParameter),
		routeType: 'PARAMETERS'
	},
	{
		accessTypes: [UserTypeEnum.SAC, UserTypeEnum.ADMIN],
		displayOnNavBar: false, // todo: investigar funcionamento do item
		name: 'Solicitações de Cancelamento de Pedido',
		key: AdminRoutesEnum.CANCELLATION_REQUESTS,
		path: AdminRoutesEnum.CANCELLATION_REQUESTS,
		children: createElement(CancellationRequests),
		routeType: 'CUSTOMERS'
	},
	{
		accessTypes: [
			UserTypeEnum.SAC,
			UserTypeEnum.ADMIN,
			UserTypeEnum.UNIDADE
		],
		displayOnNavBar: true,
		name: 'Clientes em Análise',
		key: AdminRoutesEnum.SAC_PENDING_APPROVAL,
		path: AdminRoutesEnum.SAC_PENDING_APPROVAL,
		children: createElement(PendingApproval),
		routeType: 'CUSTOMERS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Cadastro de Produtos',
		key: AdminRoutesEnum.REGISTER_PRODUCT,
		path: AdminRoutesEnum.REGISTER_PRODUCT,
		children: createElement(RegisterProduct),
		routeType: 'CD'
	},
	// {
	// 	accessTypes: [UserTypeEnum.ADMIN],
	// 	displayOnNavBar: true,
	// 	name: 'Cadastro de Categorias',
	// 	key: AdminRoutesEnum.REGISTER_CATEGORY,
	// 	path: AdminRoutesEnum.REGISTER_CATEGORY,
	// 	children: createElement(RegisterCategory)
	// },
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Push Notification',
		key: AdminRoutesEnum.PUSH_NOTIFICATION,
		path: AdminRoutesEnum.PUSH_NOTIFICATION,
		children: createElement(PushNotification),
		routeType: 'COMUNICATION'
	},

	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Histórico de Push',
		key: AdminRoutesEnum.PUSH_NOTIFICATION__HISTORY_PUSH,
		path: AdminRoutesEnum.PUSH_NOTIFICATION__HISTORY_PUSH,
		children: createElement(HistoryPush),
		routeType: 'COMUNICATION'
	},

	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Push Transacionais',
		key: AdminRoutesEnum.PUSH_NOTIFICATION__TRANSACTIONS_PUSH,
		path: AdminRoutesEnum.PUSH_NOTIFICATION__TRANSACTIONS_PUSH,
		children: createElement(TransactionalPush),
		routeType: 'COMUNICATION'
	},

	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Usuários',
		key: AdminRoutesEnum.USERS,
		path: AdminRoutesEnum.USERS,
		children: createElement(Users),
		routeType: 'CD'
	},
	{
		accessTypes: [UserTypeEnum.SAC, UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Consulta de Clientes',
		key: AdminRoutesEnum.CONSULT_CUSTOMER,
		path: AdminRoutesEnum.CONSULT_CUSTOMER,
		children: createElement(ConsultCustomer),
		routeType: 'CUSTOMERS'
	},
	{
		accessTypes: [UserTypeEnum.SAC, UserTypeEnum.ADMIN],
		displayOnNavBar: false,
		name: 'Detalhes de Clientes',
		key: AdminRoutesEnum.CONSULT_CUSTOMER_INFO,
		path: AdminRoutesEnum.CONSULT_CUSTOMER_INFO,
		children: createElement(ConsultCustomerInfo),
		routeType: 'CUSTOMERS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN, UserTypeEnum.UNIDADE],
		displayOnNavBar: true,
		name: 'Exclusão de Clientes',
		key: AdminRoutesEnum.CUSTOMER_EXCLUSION,
		path: AdminRoutesEnum.CUSTOMER_EXCLUSION,
		children: createElement(CustomerExclusion),
		routeType: 'CUSTOMERS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Exclusão de Clientes em Massa',
		key: AdminRoutesEnum.CUSTOMER_EXCLUSION_BULK_STATUS,
		path: AdminRoutesEnum.CUSTOMER_EXCLUSION_BULK_STATUS,
		children: createElement(ProcessStatusList),
		routeType: 'CUSTOMERS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: '',
		key: AdminRoutesEnum.CUSTOMER_EXCLUSION_BULK_STATUS_DETAILS,
		path: AdminRoutesEnum.CUSTOMER_EXCLUSION_BULK_STATUS_DETAILS,
		children: createElement(ProcessStatusListDetails),
		routeType: 'CUSTOMERS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Banners',
		key: AdminRoutesEnum.BANNERS_MANAGEMENT,
		path: AdminRoutesEnum.BANNERS_MANAGEMENT,
		children: createElement(BannersManagement),
		routeType: 'COMUNICATION'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Combos',
		key: AdminRoutesEnum.COMBOS,
		path: AdminRoutesEnum.COMBOS,
		children: createElement(Combos),
		routeType: 'CD'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Ordenação',
		key: AdminRoutesEnum.ODER,
		path: AdminRoutesEnum.ODER,
		children: createElement(OrderAdmin),
		routeType: 'SORT'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Cadastro de Cupom',
		key: AdminRoutesEnum.CREATE_COUPON,
		path: AdminRoutesEnum.CREATE_COUPON,
		children: createElement(CreateCoupon),
		routeType: 'COUPON'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Histórico de Cupons',
		key: AdminRoutesEnum.HISTORY_COUPON,
		path: AdminRoutesEnum.HISTORY_COUPON,
		children: createElement(HistoryCoupon),
		routeType: 'COUPON'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Gestão de entrega',
		key: AdminRoutesEnum.DELIVERY_MANAGEMENT,
		path: AdminRoutesEnum.DELIVERY_MANAGEMENT,
		children: createElement(DeliveryManagementAdmin),
		routeType: 'LOGISTIC'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Proporção Ganhos de Pontos',
		key: AdminRoutesEnum.POINTS_PROGRAM__RULES__POINT_GAIN_RATIO,
		path: AdminRoutesEnum.POINTS_PROGRAM__RULES__POINT_GAIN_RATIO,
		children: createElement(PointsEarnedRatio),
		routeType: 'POINTS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Resgate de Produtos',
		key: AdminRoutesEnum.POINTS_PROGRAM__RULES__PRODUCT_REDEMPTION,
		path: AdminRoutesEnum.POINTS_PROGRAM__RULES__PRODUCT_REDEMPTION,
		children: createElement(ProductRedemptionRules),
		routeType: 'POINTS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: false,
		name: 'Criar/Editar Regra de Ganhos de Pontos',
		key: AdminRoutesEnum.POINTS_PROGRAM__RULES__DETAIL,
		path: AdminRoutesEnum.POINTS_PROGRAM__RULES__DETAIL,
		children: createElement(PointProgramRuleDetails),
		routeType: 'POINTS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: false,
		name: 'Criar/Editar Regra de Resgate de Produtos',
		key: AdminRoutesEnum.POINTS_PROGRAM__RULES__DETAIL,
		path: AdminRoutesEnum.POINTS_PROGRAM__RULES__DETAIL,
		children: createElement(PointProgramRuleDetails),
		routeType: 'POINTS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Desafios',
		key: AdminRoutesEnum.POINTS_PROGRAM__CHALLENGES,
		path: AdminRoutesEnum.POINTS_PROGRAM__CHALLENGES,
		children: createElement(PointsProgramListOfChallenges),
		routeType: 'POINTS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: false,
		name: 'Criar/Editar Desafios',
		key: AdminRoutesEnum.POINTS_PROGRAM__CHALLENGE_DETAIL,
		path: AdminRoutesEnum.POINTS_PROGRAM__CHALLENGE_DETAIL,
		children: createElement(PointProgramChallengeDetails),
		routeType: 'POINTS'
	},
	{
		accessTypes: [UserTypeEnum.ADMIN],
		displayOnNavBar: true,
		name: 'Pedido Sugerido',
		key: AdminRoutesEnum.SUGGESTED_ORDERS,
		path: AdminRoutesEnum.SUGGESTED_ORDERS,
		children: createElement(SuggestedOrdersAdmin),
		routeType: 'SUGGESTED_ORDER'
	}
]
