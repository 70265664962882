import React from 'react'
import { UserTypeEnum } from '../../../shared/interfaces/customer'
import { adminRoutesData } from '../../Routes/adminRoutesData'
import { accessTypesIncludesUserType, isAdminPointsProgramRoutes } from './util'
import { IRouteDataV2, RouteType } from '../../Routes/types'
import RenderAccordion from './components/render-accordion.component'
import { SpacerBottom } from './admin-navbar.styles'

export function AdminNavBar({ userType }: { userType: UserTypeEnum }) {
	const enabledRoutes = adminRoutesData
		.filter((route) => route.displayOnNavBar)
		.filter((route) =>
			accessTypesIncludesUserType(route.accessTypes, userType)
		)
	const pointsProgramRoutes = enabledRoutes.filter((route) =>
		isAdminPointsProgramRoutes(route.key)
	)
	const navBarRoutes = enabledRoutes.filter(
		(route) => !isAdminPointsProgramRoutes(route.key)
	)
	const filterRoutesByType = (routes: IRouteDataV2[], type: RouteType) =>
		routes.filter((route) => route.routeType === type)
	const parametersRoutes = filterRoutesByType(
		navBarRoutes,
		RouteType.PARAMETERS
	)
	const sortRoutes = filterRoutesByType(navBarRoutes, RouteType.SORT)
	const couponRoutes = filterRoutesByType(navBarRoutes, RouteType.COUPON)
	const cdRoutes = filterRoutesByType(navBarRoutes, RouteType.CD)
	const customerRoutes = filterRoutesByType(navBarRoutes, RouteType.CUSTOMERS)
	const comunicationRoutes = filterRoutesByType(
		navBarRoutes,
		RouteType.COMUNICATION
	)
	const logisticRoutes = filterRoutesByType(navBarRoutes, RouteType.LOGISTIC)
	const programPointsRoutes = filterRoutesByType(
		pointsProgramRoutes,
		RouteType.POINTS
	)

	const suggestedOrderRoutes = filterRoutesByType(
		navBarRoutes,
		RouteType.SUGGESTED_ORDER
	)
	return (
		<>
			<SpacerBottom />
			{!!parametersRoutes.length &&
				RenderAccordion('Parâmetros', parametersRoutes)}
			{!!sortRoutes.length && RenderAccordion('Ordenação', sortRoutes)}
			{!!sortRoutes.length && RenderAccordion('Cupom', couponRoutes)}
			{!!cdRoutes.length && RenderAccordion('Configurações CD', cdRoutes)}
			{!!customerRoutes.length &&
				RenderAccordion('Configuração Clientes', customerRoutes)}
			{!!comunicationRoutes.length &&
				RenderAccordion('Comunicação', comunicationRoutes)}
			{!!logisticRoutes.length &&
				RenderAccordion('Logística', logisticRoutes)}
			{!!programPointsRoutes.length &&
				RenderAccordion('Programa de pontos', programPointsRoutes)}
			{!!suggestedOrderRoutes.length &&
				RenderAccordion('Pedido Sugerido', suggestedOrderRoutes)}
		</>
	)
}
