import { ICartItemTypeEnum } from './cart'
import { IPriceAction } from './priceAction'

export interface IMaterial {
	SalesOrganizationID: string
	MaterialID: string
	InternalMaterialID?: string
	Description: string
	UnitMeasureSale: string
	StockPosition: string
	ImageUrl: string
	PackageImageUrl: string | null
	FixedPrice?: string
	PriceByQuantity: IPriceByQuantity[]
	Characteristics: ICharacteristics[]
	priceAction?: IPriceAction[]
	ScoreValue?: string
	ScoreRate?: string
	rescueProduct?: boolean
	typeItem?: ICartItemTypeEnum
	itemUnitiesQuantity?: string
	PricePerUnit?: number
	typeOfContainer?: string
	minimumAmountToCompleteAChallenge?: number
	ListingPosition?: number
	CategoryListingPosition?: number
	materialNumber?: string
	ID?: string
}

export interface IPriceByQuantity {
	Quantity: number
	Price: number
}

export interface ICharacteristics {
	Type: string
	Description: string
}

export const characteristicTypeEnum: any = {
	Category: 'Categoria',
	Brand: 'Marca',
	Package: 'Tamanho',
	PackageType: 'Tipo',
	Family: 'Família',
	Style: 'Estilo',
	Coloration: 'Coloração',
	AlcoholContent: 'Teor Alcoólico',
	UnitBarcode: 'Código de Barras Unidade',
	PackageBarcode: 'Código de Barras Pacote',
	IBU: 'IBU'
}

export interface IMaterialPosition {
	ID?: string
	listingPosition: number
}
