import { FilterNameEnum } from '../../components/filters-by-hieararchy/types'
import { CreationStatus } from '../../types/CreationStatus'

export interface IHeaderRulePoint {
	ruleID?: string
	autoIncRuleId?: string
	scoreValue?: string
	initialDate?: string
	finalDate?: string
	itemsRulePointScoreValue?: IItemRulePointScoreValue[]
	itemsRulePointScoreRate?: IItemRulePointScoreRate[]
	filtersRulePoint?: IFilterRulePoint[]
	filtersRulePointNormalized?: FilterHierarchyInput
	amountOfCustomers?: number
	status?: StatusRulePointsProgramEnum
	observation?: string
	ruleRegistrationDate?: string
	createdAt?: string
	creationStatus: CreationStatus
	filters: string
}
export class FilterHierarchyInput {
	diretor?: string[]
	GRC?: string[]
	codeResales?: string[]
	ufs?: string[]
	cities?: string[]
	channels?: string[]
	codePdv?: string[]
}

export interface IItemRulePointScoreValue {
	ruleID?: string
	productID: string
}

export interface IItemRulePointScoreRate {
	ruleID?: string
	productID: string
	scoreRate: string
}

export interface IFilterRulePoint {
	ruleID?: string
	paramName: RuleParamsNameEnum | FilterNameEnum
	paramValue: string
}

export enum RuleParamsNameEnum {
	DIRECTOR = 'DIRECTOR',
	GOV = 'GOV',
	RESALE = 'RESALE',
	STATE = 'STATE',
	CITY = 'CITY',
	CHANNEL = 'CHANNEL',
	PDV = 'PDV'
}

export enum StatusRulePointsProgramEnum {
	ACTIVE = 'ATIVO',
	INACTIVE = 'INATIVO'
}

export interface IFilterSalesHierarchyDirector {
	director: string
	govs: IFilterSalesHierarchyGov[]
}

export interface IFilterSalesHierarchyGov {
	gov: string
	resales: IFilterSalesHierarchyResale[]
}

export interface IFilterSalesHierarchyResale {
	resale: string
}

export interface ISelectDefaultInterface {
	label: string
	value: string
}

export interface IPointProgramRuleFilters {
	salesHierarchy: IFilterSalesHierarchyDirector[]
	channels: ISelectDefaultInterface[]
	ufs: ISelectDefaultInterface[]
	cities: ISelectDefaultInterface[]
}
