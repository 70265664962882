import styled from "styled-components";

export const WrapperSectionTitle = styled.h2`
  	text-align: left;
		font-size: ${(props) => props.theme.font.size.TEXT_SM.size}rem;
		line-height:${(props) => props.theme.font.size.TEXT_SM.line_height}rem;
		padding: 0 0 8px 0;
		border-bottom: 1px solid ${props => props.theme.palette.gray.light};
`;

export const WrapperCheckbox = styled.div`
  width: 100%;
  padding: 16px;
`;