import React from 'react'
import { FullParameter } from '../../../shared/interfaces/fullParam'
import ResaleParameterItem from '../ResaleParameterItem'
import { IProps } from './types'

function ResaleParametersTable(props: IProps): JSX.Element {
	const { data = [], removeParam, updateParamKey, addTag, removeTag } = props

	const headers = ['Nome', 'Descritivo', 'Tipo', 'Valor do Tipo', 'Valor']

	const headerProps = (i: number) => ({
		key: i,
		className: 'param-table-header'
	})

	return (
		<div className="custom-table-container">
			{data && data.length ? (
				<table className="param-table">
					<thead>
						<tr>
							{headers.map((header, i) => (
								<th {...headerProps(i)}>{header}</th>
							))}
						</tr>
					</thead>
					<tbody>
						{data?.map((item: FullParameter, i: number) => (
							<ResaleParameterItem
								addTag={addTag}
								index={i}
								item={item}
								key={i}
								removeParam={removeParam}
								removeTag={removeTag}
								updateParamKey={updateParamKey}
							/>
						))}
					</tbody>
				</table>
			) : (
				<div className="table-empty-message">
					<h1>Nenhum dado para mostrar.</h1>
				</div>
			)}
		</div>
	)
}

export default ResaleParametersTable
