import React, { useCallback } from 'react'
import LoadingCcontainer from '../../components/loading-container/loading-container.component'
import DefaultLayoutAdmin from '../../layout/default/deafult-admin.layout'
import FilterByCd from './components/filter-by-cd/filter-by-cd.component'
import DaysDeliveryTable from './components/days-delivery-table/days-delivery-table.component'
import { Container } from '@material-ui/core'
import EmptyTable from '../../components/empty-table/empty-table.component'
import { StyledDeliveryManagement } from './delivery-management.styles'

function DeliveryManagementAdmin() {
	const [isLoading, setIsLoading] = React.useState(false)
	const [selectedCds, setSelectCds] = React.useState<string[]>([])

	const handleLoading = useCallback((state: boolean) => {
		setIsLoading(state)
	}, [])

	return (
		<>
			<DefaultLayoutAdmin>
				<>
					<LoadingCcontainer isLoading={isLoading} />
					<StyledDeliveryManagement>
						<FilterByCd
							setSelectCds={(cd: string[]) => setSelectCds(cd)}
							handleLoading={handleLoading}
						/>
					</StyledDeliveryManagement>
				</>
			</DefaultLayoutAdmin>
			<Container>
				{selectedCds.length > 0 && (
					<DaysDeliveryTable
						handleLogin={setIsLoading}
						cds={selectedCds}
					/>
				)}
				{selectedCds.length === 0 && (
					<EmptyTable
						isDefaultTable={false}
						emptyTableMessage="Selecione os filtros para exibirmos a listagem de Gerenciamento"
					/>
				)}
			</Container>
		</>
	)
}
export default DeliveryManagementAdmin
