import styled from 'styled-components'

export const ButtonWrapper = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(props) => props.theme.palette.primary.main};
	color: ${(props) => props.theme.palette.white.main};

	font-weight: ${(props) => props.theme.font.weight.bold};
	border: none;
	padding: 16px 24px;
	border-radius: 5px;

	transition: 20ms ease-in-out;

	&:hover {
		cursor: pointer;
		background: ${(props) => props.theme.palette.secondary.main};
	}
`

export const ButtonDisabled = styled.button`
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(props) => props.theme.palette.lightGray.main};
	color: ${(props) => props.theme.palette.lightGray.contrastText};

	font-weight: ${(props) => props.theme.font.weight.bold};
	border: none;
	padding: 16px 24px;
	border-radius: 5px;
	height: 48px;
	width: 100%;

	&:hover {
		cursor: not-allowed;
	}
`

export const ButtonOutlined = styled.button`
	display: flex;
	align-items: center;
	background: ${(props) => props.theme.palette.white.main};
	color: ${(props) => props.theme.palette.primary.main};
	font-weight: ${(props) => props.theme.font.weight.bold};
	border: none;
	padding: 16px 24px;
	border-radius: 5px;
	height: 48px;
	width: 100%;

	&:hover {
		cursor: pointer;
	}
`
export const IconContainer = styled.div`
	padding-left: ${(props) => props.theme.spacingOptions.XSM}px;
`
