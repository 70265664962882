import cogoToast from 'cogo-toast'
import React, {
	createElement,
	useCallback,
	useEffect,
	useMemo,
	useState
} from 'react'
import { ActionMeta } from 'react-select'

import { useBoolean } from '../../shared/hooks/useBoolean'
import { Maybe, Nullable } from '../../shared/interfaces/common'
import { sortName } from '../../shared/utils/sorts'
import { DEFAULT_PROD_IMAGE, DEFAULT_PRODUCT } from './data'
import {
	getAvailableProductMeasures,
	getMaterialChannelCustomByID,
	getProducts,
	handleFilterChannels,
	postProduct,
	uploadImage
} from './service'
import {
	IOptions,
	IProduct,
	ISelectPackageType,
	ISelectScoreValueType,
	ISelectUnitMeasure,
	ISelectCategory,
	ITableData
} from './types'
import RegisterProduct from './view'
import { include } from '../../shared/utils/boolean'

import FileTextIcon from '../../assets/images/file-text.svg'
import { defaultScoreValueOptions } from '../../shared/utils/points'
import { ISelectOption } from '../../shared/utils/SelectOptions'
import { categoriesServices } from '../../shared/services/categories.services'

import RegisterProductMapper from './mapper'
import { useFeatureFlags } from '../../context/feature-flag/feature-flag.context'

type ProductImages = {
	unitImage: {
		file: File | ''
		name: string
		preview: string
	}
	packImage: {
		file: File | ''
		name: string
		preview: string
	}
}

function RegisterProductContainer() {
	// const [productsList, setProductsList] = useState<IProduct[]>([])
	const [targetProduct, setTargetProduct] = useState(DEFAULT_PRODUCT)
	const [productImages, setProductImages] =
		useState<ProductImages>(DEFAULT_PROD_IMAGE)
	const [isNewProduct, registerNewProduct, editProduct] = useBoolean(false)
	const [isModalOpen, openModal, closeModal] = useBoolean(false)
	const [isLoading, startLoading, stopLoading] = useBoolean(false)
	const [isProductValid, setIsProductValid] = useState(false)

	const [data, setData] = useState<ITableData[]>([])
	const [tableData, setTableData] = useState<ITableData[]>([])

	const [customChannel, setCustomChannel] = useState(false)
	const [isBonification, setIsBonification] = useState<boolean>(false)
	const [options, setOptions] = useState<IOptions>()

	const [channelOptions, setFilterChannelOptions] = useState<ISelectOption[]>(
		[] as ISelectOption[]
	)
	const [selectedOptionChannels, setselectedOptionChannels] = useState<
		ISelectOption[]
	>([] as ISelectOption[])

	const { featureFlags } = useFeatureFlags()
	const isFeatureFlagBonificationActive = featureFlags.PRODUTO_BONIFICADO

	const columns = useMemo(
		() => [
			{ Header: 'Código do Produto', accessor: 'code' },
			{ Header: 'Nome do Produto', accessor: 'name', sortType: sortName },
			{ Header: 'Tipo', accessor: 'packageType' },
			{ Header: 'Formato', accessor: 'unitMeasure' },
			{ Header: 'Tamanho', accessor: 'size' },
			{ Header: 'Proporção de Pontos', accessor: 'ScoreValue' },
			{ Header: 'Resgate', accessor: 'ScoreRate' },
			{
				Header: '',
				accessor: 'button',
				disableSortBy: true
			}
		],
		[]
	)

	function handleCheckbox() {
		setCustomChannel((check) => !check)
	}

	const handleChangeIsBonification = useCallback(() => {
		setIsBonification((check) => !check)
	}, [])

	function populateChannels() {
		;(async () => {
			try {
				if (!targetProduct.MaterialID || !channelOptions) {
					setCustomChannel(false)
					setselectedOptionChannels([])
					return
				}

				const array = await getMaterialChannelCustomByID(
					targetProduct.MaterialID
				)
				if (!array) return

				const buildingChannelsSelected = channelOptions.filter(
					(channel) => array.includes(channel.value)
				)
				setselectedOptionChannels(buildingChannelsSelected)
				setCustomChannel(!!buildingChannelsSelected.length)
			} finally {
			}
		})()
	}

	function handleProductDetail(product?: IProduct) {
		if (product) {
			editProduct()
			openModal()
			if (!product.ScoreRate) product.ScoreRate = ''
			if (!product.ScoreValue) product.ScoreValue = ''
			setTargetProduct({ ...product, categoryId: product.CategoryID })
			setProductImages({
				unitImage: {
					file: '',
					name: '',
					preview: product.ImageUrl || ''
				},
				packImage: {
					file: '',
					name: '',
					preview: product.PackageImageUrl || ''
				}
			})
			setIsBonification(product.showInBonification ?? false)
			return
		}
		registerNewProduct()
		openModal()
		setTargetProduct(DEFAULT_PRODUCT)
	}

	function renderButton(product: IProduct) {
		return (
			<button
				className="edit"
				onClick={() => handleProductDetail(product)}
				data-testid="edit-product-button">
				<img className="edit-img" src={FileTextIcon} alt="Editar" />
			</button>
		)
	}

	function handleCloseModal() {
		closeModal()
		setProductImages(DEFAULT_PROD_IMAGE)
		setCustomChannel(false)
		setselectedOptionChannels([])
		setIsBonification(false)
	}

	function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
		const { id, value } = e.target

		setTargetProduct((oldState) => ({
			...oldState,
			[id]: value
		}))
	}

	function handleSelectPackageType(
		selectedValue: Maybe<Nullable<ISelectPackageType>>,
		_: ActionMeta<ISelectPackageType>
	): void {
		setTargetProduct({
			...targetProduct,
			PackageType: selectedValue?.value || ''
		})
	}

	function handleSelectScoreValueType(
		selectedValue: Maybe<Nullable<ISelectScoreValueType>>,
		_: ActionMeta<ISelectScoreValueType>
	): void {
		setTargetProduct({
			...targetProduct,
			ScoreValue: selectedValue?.value || ''
		})
	}

	function handleSelectUnitMeasure(
		selectedValue: Maybe<Nullable<ISelectUnitMeasure>>,
		_: ActionMeta<ISelectUnitMeasure>
	): void {
		setTargetProduct({
			...targetProduct,
			UnitMeasureSale: selectedValue?.value || ''
		})
	}

	function handleSelectCategory(
		selectedValue: Maybe<Nullable<ISelectCategory>>,
		_: ActionMeta<ISelectCategory>
	): void {
		setTargetProduct({
			...targetProduct,
			Category: selectedValue?.label || '',
			categoryId: selectedValue?.value || ''
		})
	}

	function handleChannel(selectedValue: ISelectOption[]): void {
		setselectedOptionChannels(selectedValue)
	}

	async function fetchAvailableSelectOptions() {
		const availableOptions = await getAvailableProductMeasures()
		const categories = await categoriesServices.getCategoriesAdmin()

		setOptions({
			UnitMeasure: availableOptions || [],
			PackageType: ['Retornável', 'Descartável'],
			ScoreValueType: defaultScoreValueOptions,
			categories
		})
	}

	function fetchData() {
		;(async () => {
			try {
				const result = await handleFilterChannels()

				if (!result) return

				setFilterChannelOptions(result)
			} finally {
			}
		})()
	}

	async function fetchProducts() {
		try {
			startLoading()
			fetchData()
			const allProductsList = await getProducts()

			const data = allProductsList.map((item) => ({
				code: item.MaterialID,
				name: item.SiteDescription,
				packageType: item.PackageType,
				unitMeasure: item.UnitMeasureSale,
				size: item.Package,
				ScoreValue: item.ScoreValue,
				ScoreRate: item.ScoreRate,
				button: renderButton(item)
			}))

			setData(data)
			setTableData(data)

			stopLoading()
		} catch (error) {
		} finally {
			stopLoading()
		}
	}

	function handleFilterOnChange(
		event: React.ChangeEvent<HTMLInputElement>
	): void {
		const value = event.target.value
		const list = data.filter((item) => {
			return (
				include(item.code, value) ||
				include(item.name, value) ||
				include(item.packageType, value) ||
				include(item.size, value) ||
				include(item.unitMeasure, value)
			)
		})

		setTableData(list)
	}

	async function deleteProduct() {
		startLoading()
		const data: IProduct = {
			...targetProduct,
			LogicalDelete: 'X'
		}

		try {
			await postProduct(data)

			fetchProducts()
			handleCloseModal()
			stopLoading()
			cogoToast.success(`Produto removido com sucesso`, {
				position: 'bottom-center',
				heading: 'Produto removido'
			})
		} catch (error) {
			stopLoading()
			handleCloseModal()
			console.error({ error })
		}
	}

	function createProductValidation() {
		const {
			MaterialID,
			UnitMeasureSale,
			Description,
			SiteDescription,
			categoryId
		} = targetProduct

		const isId = MaterialID.length >= 1
		const isMeasure = UnitMeasureSale.length >= 1
		const isDescription = Description.length >= 1
		const isSiteDescription = SiteDescription.length >= 1
		const isCategoryFilled = !!categoryId

		const result =
			isId &&
			isMeasure &&
			isDescription &&
			isSiteDescription &&
			isCategoryFilled

		setIsProductValid(result)
	}

	function returnChannels() {
		if (!customChannel) return []
		return selectedOptionChannels.map((channels) => channels.value)
	}

	async function handleImageUrlReturn(payload: {
		file: File | ''
		name: string
		preview: string
	}): Promise<string> {
		if (!payload.file) return payload.preview

		const fileName = payload.name.replace(
			/^(\w|-)+/g,
			targetProduct.MaterialID
		)

		const imageUrl = await uploadImage({ file: payload.file, fileName })

		return typeof imageUrl !== 'boolean' ? imageUrl.url : ''
	}

	async function createProduct() {
		try {
			const { unitImage, packImage } = productImages

			const [imageUrl, packageImageUrl] = await Promise.all([
				handleImageUrlReturn(unitImage),
				handleImageUrlReturn(packImage)
			])
			let product

			if (targetProduct.ID === '') {
				product = RegisterProductMapper.toPersistence({
					...targetProduct,
					HasChannelCustom: customChannel,
					ImageUrl: imageUrl,
					PackageImageUrl: packageImageUrl,
					showInBonification: isFeatureFlagBonificationActive
						? isBonification
						: false
				})
			} else {
				product = RegisterProductMapper.toPersistenceEdit({
					...targetProduct,
					HasChannelCustom: customChannel,
					ImageUrl: imageUrl,
					PackageImageUrl: packageImageUrl,
					showInBonification: isFeatureFlagBonificationActive
						? isBonification
						: false
				})
			}

			const channels = returnChannels()
			await postProduct(product, channels)

			cogoToast.success(
				`O produto #${targetProduct.MaterialID} foi cadastrado com sucesso`,
				{
					position: 'bottom-center',
					heading: 'Produto Cadastrado'
				}
			)
		} catch (e) {
			console.error(e)
		} finally {
			await fetchProducts()
			stopLoading()
			handleCloseModal()
		}
	}

	useEffect(() => {
		fetchProducts()
		// eslint-disable-next-line
	}, [])
	useEffect(populateChannels, [
		isModalOpen,
		channelOptions,
		targetProduct.MaterialID
	])
	useEffect(() => {
		fetchAvailableSelectOptions()
		// eslint-disable-next-line
	}, [])

	// eslint-disable-next-line
	useEffect(createProductValidation, [targetProduct])
	const viewProps = {
		columns,
		isLoading,
		isNewProduct,
		targetProduct,
		isModalOpen,
		openModal,
		handleCloseModal,
		handleProductDetail,
		handleChange,
		handleSelectPackageType,
		handleSelectScoreValueType,
		handleSelectUnitMeasure,
		handleSelectCategory,
		createProduct,
		setProductImages,
		productImages,
		options,
		deleteProduct,
		isProductValid,
		tableData,
		handleFilterOnChange,
		channelOptions,
		handleChannel,
		selectedOptionChannels,
		handleCheckbox,
		customChannel,
		isBonification,
		handleChangeIsBonification,
		isFeatureFlagBonificationActive
	}

	return createElement(RegisterProduct, viewProps)
}

export default RegisterProductContainer
