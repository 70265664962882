import { IMaterial, IMaterialPosition } from '../interfaces/material'
import { apiAdmin, apiCustomer } from './axiosInstace'

export const materialsServices = {
	updateMaterialsList: async function (
		materialsList: IMaterialPosition[]
	): Promise<any> {
		const { data } = await apiAdmin.patch(`/materials`, materialsList)
		return data
	}
}
interface ComboMaterials {
	productIds: string[]
	salesOrganizationId: string
}

export async function getComboMaterials({
	productIds,
	salesOrganizationId
}: ComboMaterials): Promise<IMaterial[]> {
	const data = {
		productIds,
		salesOrganizationId
	}
	const response = await apiCustomer.post('/materials/material-by-id', data)

	return response?.data || []
}
