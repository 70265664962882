import cogoToast from 'cogo-toast'
import {
	__,
	adjust,
	allPass,
	any,
	complement,
	equals,
	findIndex,
	mergeDeepLeft,
	pipe,
	prop,
	propEq,
	propSatisfies,
	remove,
	sortBy,
	update
} from 'ramda'
import { createElement, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useBoolean } from '../../shared/hooks/useBoolean'
import { useTypedSelector } from '../../shared/hooks/useTypedSelector'
import { Maybe } from '../../shared/interfaces/common'
import { FullParameter } from '../../shared/interfaces/fullParam'
import { displayError } from '../../shared/utils/cogoToast'
import {
	formatItemsEnum,
	validateTimeOrder,
	formatReceived
} from '../../shared/utils/resale'
import cogoDefaultOptions from '../../shared/utils/toaster'
import { fetchParams } from '../RegisterParameter/service'
import { IParamValueObj, PossibleInputValues } from '../RegisterParameter/types'
import { fetchCompanyParam, saveParams } from './service'
import { IViewProps } from './types'
import View from './view'
import { format } from 'date-fns'
import { AdminRoutesEnum } from '../Routes/adminRoutesEnum'

function ResaleParametersContainer(): JSX.Element {
	const history = useHistory()

	const { resale } = useTypedSelector(['resale'])

	const INITIAL: FullParameter[] = []

	const [paramList, setParamList] = useState(INITIAL)
	const [loading, startLoading, stopLoading] = useBoolean(false)

	const { SalesOrganizationID: id } = resale

	const handleFetchParamList = async () => {
		try {
			startLoading()
			const allParams = await fetchParams()

			const specificParams = await fetchCompanyParam(id)

			const treatedParams: any = allParams.map((param) => {
				const hasSpecific = propEq('Parameter', param.Name)

				const exists = specificParams.find(hasSpecific)

				return { ...param, ...(exists ? exists : {}) }
			}, [])

			const formatedMoney = treatedParams.map((param: FullParameter) => {
				if (param.Type === 'MONEY' && param.Items?.length) {
					const Items = param.Items.map((item) => ({
						...item,
						Value: formatReceived(item.Value)
					}))

					return { ...param, Items }
				}

				return param
			})

			const Items: IParamValueObj[] = []

			const sortByPos = sortBy(prop('Position'))

			const withItems = (x: any) => ({
				...x,
				Items: x.Items ? sortByPos(x.Items) : Items
			})

			const initialized = formatedMoney.map(withItems)

			setParamList(initialized as any)
		} catch (error) {
		} finally {
			stopLoading()
		}
	}

	useEffect(() => {
		handleFetchParamList()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleSubmit = async () => {
		startLoading()

		const isValueValid = allPass([
			complement(equals(undefined)),
			complement(equals(''))
		])

		const anyHasValue = any(propSatisfies(isValueValid, 'Value'))

		const isValid = pipe(prop('Items') as any, anyHasValue)

		const shouldSend = paramList.filter(isValid)

		const updated = shouldSend.map((x) => ({
			Parameter: x.Name,
			SalesOrganizationID: id,
			Items: formatItemsEnum[x.Type](x.Items),
			LogicalDelete: ''
		}))

		try {
			await saveParams(id, updated)
			cogoToast.success(
				'Parâmetros salvos com sucesso',
				cogoDefaultOptions
			)
		} catch (error) {
		} finally {
			stopLoading()
		}
	}

	// TODO: split this, it does too much
	const updateParamKey =
		<K extends keyof FullParameter>(
			index: number,
			key: K,
			pos?: 'start' | 'end'
		) =>
		(input: Maybe<PossibleInputValues>) => {
			if (!input) return

			const fullItem = paramList[index]

			const isBool = fullItem.Type === 'BOOLEAN'
			const isTime = fullItem.Type === 'TIME'

			const isSingle = fullItem.TypeValue === 'SINGLE'

			const isEnd = pos === 'end'
			const isEvent = Boolean((input as any)?.target)
			const isOption = Boolean((input as any)?.value)

			const obj = isEvent ? (input as any).target : isOption ? input : {}

			const { value = input } = obj
			let Items
			if (fullItem.Items.length) {
				Items = fullItem.Items
			} else {
				Items = [{ Position: 1, Value: '0' }]
			}

			if (key === 'Items') {
				const Value = isBool ? String(obj.checked) : value

				const firstItem = { Position: 1, Value }

				if (isTime && isSingle) {
					const isInvalidDate = String(value) === 'Invalid Date'
					if (isInvalidDate) return
					const payload = {
						Items: [
							{
								Position: 1,
								Value: format(firstItem.Value, 'HH:mm')
							}
						]
					}

					setParamList(adjust(index, mergeDeepLeft(payload)))

					return
				}

				const end = [Items?.[0], { Position: 2, Value: value }]

				const wanted = findIndex(propEq('Position', 1))

				const updates = update(__ as any, firstItem, Items)

				const updatedItems = pipe(wanted, updates as any)(Items)

				const payload = { Items: isEnd ? end : updatedItems }

				const isInvalidDate = String(value) === 'Invalid Date'

				const shouldValidateOrder = pos && isTime && !isInvalidDate

				const orderValid = shouldValidateOrder
					? validateTimeOrder(pos, payload.Items as IParamValueObj[])
					: true

				if (!orderValid) return

				setParamList(adjust(index, mergeDeepLeft(payload)))

				return
			}

			const { TypeValue } = fullItem

			const useObjValue = TypeValue === 'INTERVAL'

			const valueToUse = pos && useObjValue ? { [pos]: value } : value

			const isUniq =
				key === 'Type' && (value === 'BOOLEAN' || value === 'STRING')

			const unique = isUniq ? { TypeValue: 'SINGLE' as 'SINGLE' } : {}

			const payload = { [key]: valueToUse, ...unique }

			setParamList(adjust(index, mergeDeepLeft(payload)))
		}

	const addTag = (index: number) => (Value: string) => {
		const fullItem = paramList[index]

		const { Items } = fullItem

		if (Items.length === 10) {
			displayError('O máximo de valores é 10')
			return
		}

		const Position = Items.length + 1

		const newItems = [...Items, { Position, Value }]

		const payload = { Items: newItems }

		setParamList(adjust(index, mergeDeepLeft(payload)))
	}

	const removeTag = (paramIdx: number) => (tagIdx: number) => {
		const fullItem = paramList[paramIdx]

		const { Items } = fullItem

		const newItems = Items.filter((_, i) => i !== tagIdx)

		const payload = { Items: newItems }

		setParamList(adjust(paramIdx, mergeDeepLeft(payload)))
	}

	const removeParam = (i: number) => setParamList(remove(i, i))

	const handleGoBack = () => history.replace(AdminRoutesEnum.RESALE_LIST)

	const viewProps: IViewProps = {
		data: paramList,
		loading,
		resale,
		addTag,
		handleGoBack,
		handleSubmit,
		removeParam,
		removeTag,
		updateParamKey
	}

	return createElement(View, viewProps)
}

export default ResaleParametersContainer
