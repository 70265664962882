import React, { useEffect } from 'react'
import LayoutModal from '../../../../../layout/layout-modal/layout-modal.layout'
import ImpactedClients from '../../../../../components/impacted-clients/impacted-clients'
import { EditModalWrapper } from './edit-banner-modal.styles'
import { DataSelectorList } from '../../../../../components/data-selector-list'
import { IEditModalBanner } from './edit-banner-modal.types'
import MidiaContainer from '../../midia-container/midia-container.component'
import { getProducts } from '../../../service'
import { IProduct } from '../../../types'
import { DateRangePicker } from '../../../../../components/date-range-picker'
import { DateRange } from 'react-day-picker-8'
import FloatLabelLocaleInput from '../../../../../components/FloatLabelLocaleInput'
import { primaryInputStyle } from '../../../../../shared/styles/theme'
import themeProvider, {
	colorThemeProvider
} from '../../../../../theme/themeProvider'
import {
	productItems,
	categoryItems
} from '../../../../push-notifications-admin/components/behavior-filter/utils/arrayDynamicItems'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { ActionType, Destiny } from '../../../banners-management.types'
import { editBanner } from '../service'
import cogoToast from 'cogo-toast'
import cogoDefaultOptions from '../../../../../shared/utils/toaster'
import Loading from '../../../../../components/Loading'
import { IProductItem } from '../../../../push-notifications-admin/components/behavior-filter/behavior-filter.types'
import { getPriority } from '../../../../../shared/services/banner.service'
import { optionProps } from '../../form-create-banner/form-create-banner-types'
import { createArrayWithObjects } from '../../../utils/create-array-with-objects'

const EditBannerModal = ({
	isOpen = true,
	handleToggleModal,
	banner,
	customers,
	onSaveBanner
}: IEditModalBanner) => {
	const [editTitle, setEditTitle] = React.useState<string>(banner.title)
	const [destinationsLink] = React.useState<any[]>([
		{
			name: 'Home',
			value: Destiny.HOME
		},
		{
			name: 'Login',
			value: Destiny.LOGIN
		}
	])
	const [destinationLinkSelected, setDestinationLinkSelected] =
		React.useState({
			name: '',
			value: banner.targetPage
		})
	const [selectedDate, setSelectedDate] = React.useState<DateRange>({
		from: new Date(banner.initialDate + 'T00:00:00'),
		to: new Date(banner.finalDate + 'T00:00:00')
	})
	const [prioritySelected, setPrioritySelected] = React.useState({
		name: banner.targetOrder,
		value: banner.targetOrder
	})
	const [priorityNumber, setPriorityNumber] = React.useState<Number>(0)
	const [priorityOptions, setPriorityOptions] = React.useState<optionProps[]>(
		[]
	)

	const [actions] = React.useState([
		{
			value: ActionType.OPEN_PRODUCT,
			name: 'Abrir um Produto Específico'
		},
		{
			value: ActionType.OPEN_CATEGORY,
			name: 'Abrir Categoria de Produto'
		},
		{
			value: ActionType.OPEN_COMBOS,
			name: 'Abrir o Módulo de Combos'
		},
		{
			value: ActionType.OPEN_CHALLENGES,
			name: 'Abrir o Módulo de Desafios'
		},
		{
			value: ActionType.OPEN_PROGRAM_POINTS,
			name: 'Abrir o Módulo de Pontos'
		},
		{
			value: ActionType.OPEN_VIDEO,
			name: 'Abrir Vídeo'
		}
	])
	const [actionSelected, setActionSelected] = React.useState({
		value: banner.actionType,
		name: ''
	})
	const [showSelectProducts, setShowSelectProducts] = React.useState(false)
	const [products, setProducts] = React.useState<IProduct[]>([])
	const [productsForDisplay, setProductsForDisplay] = React.useState<any[]>(
		[]
	)
	const [isLoading, setIsLoading] = React.useState(false)
	const [productSelected, setProductSelected] = React.useState<IProductItem>({
		value: '',
		name: banner.selectedProduct
	})

	const [categorySelected, setCategorySelected] =
		React.useState<IProductItem>({
			value: banner.selectedCategory,
			name: banner.selectedCategory
		})

	const [selectedImage, setSelectedImage] = React.useState(banner.imageURL)
	const [selectedVideo, setSelectedVideo] = React.useState(banner.videoURL)
	const [disabledButtonEdit, setDisabledButtonEdit] = React.useState(false)

	function changeSetSelectedDate(range: any) {
		setSelectedDate(range)
	}

	function onSelectionChange(option: any) {
		setActionSelected(option)
		setShowSelectProducts(
			option.value !== ActionType.OPEN_CHALLENGES &&
				option.value !== ActionType.OPEN_COMBOS &&
				option.value !== ActionType.OPEN_PROGRAM_POINTS &&
				option.value !== ActionType.OPEN_VIDEO
		)
	}

	const handleChangeDestinationLinkChange = (option: any) => {
		setDestinationLinkSelected(option)
	}

	const handleChangePriority = (option: any) => {
		setPrioritySelected(option)
	}

	React.useEffect(() => {
		setShowSelectProducts(
			banner.actionType !== ActionType.OPEN_CHALLENGES &&
				banner.actionType !== ActionType.OPEN_COMBOS &&
				banner.actionType !== ActionType.OPEN_PROGRAM_POINTS &&
				banner.actionType !== ActionType.OPEN_VIDEO
		)
	}, [banner.actionType])

	React.useEffect(() => {
		setIsLoading(true)
		const handleGetProducts = async () => {
			const data = await getProducts()

			const currentProduct = data.find(
				(e) => e.MaterialID === banner.selectedProduct
			)

			if (currentProduct) {
				setProductSelected({
					name: currentProduct.SiteDescription,
					value: currentProduct.MaterialID
				})
			}

			setProducts(data)
		}
		handleGetProducts()
		setIsLoading(false)
	}, [banner.selectedProduct])

	useEffect(() => {
		if (actionSelected.value === ActionType.OPEN_PRODUCT) {
			setProductsForDisplay(productItems(products))
		} else if (actionSelected.value === 'OPEN_CATEGORY') {
			setProductsForDisplay(categoryItems(products))
		} else {
			setProductSelected({
				value: '',
				name: ''
			})
		}
	}, [actionSelected, products, banner.actionType])

	async function handleEditMOdal() {
		setIsLoading(true)
		try {
			if (!banner.ID) return
			await editBanner(banner.ID, {
				title: editTitle,
				imageURL: selectedImage,
				videoURL: selectedVideo,
				initialDate: new Date(String(selectedDate.from))
					.toISOString()
					.split('T')[0],
				finalDate: new Date(String(selectedDate.to))
					.toISOString()
					.split('T')[0],
				targetPage: destinationLinkSelected.value,
				targetOrder: prioritySelected.value,
				actionType: actionSelected.value,
				selectedProduct:
					actionSelected.value === 'OPEN_PRODUCT'
						? productSelected.value
						: '',
				selectedCategory:
					actionSelected.value === 'OPEN_CATEGORY'
						? categorySelected.value
						: '',
				platform: banner.platform
			})
			cogoToast.success(
				'Banner alterado com sucesso.',
				cogoDefaultOptions
			)
			onSaveBanner()
			handleToggleModal()
		} catch (error) {
			cogoToast.error('Erro ao editar banner.', cogoDefaultOptions)
		} finally {
			setIsLoading(false)
		}
	}

	React.useEffect(() => {
		if (
			(actionSelected.value === 'OPEN_PRODUCT' &&
				productSelected.value.length === 0) ||
			(actionSelected.value === 'OPEN_CATEGORY' &&
				categorySelected.value.length === 0)
		) {
			setDisabledButtonEdit(true)
		} else {
			setDisabledButtonEdit(false)
		}
	}, [
		actionSelected,
		categorySelected.value.length,
		productSelected.value.length
	])

	React.useEffect(() => {
		if (!selectedImage && !selectedVideo) {
			setDisabledButtonEdit(true)
		} else {
			setDisabledButtonEdit(false)
		}
	}, [selectedImage, selectedVideo, banner.imageURL, banner.videoURL])

	React.useEffect(() => {
		setIsLoading(true)
		const handleGetBanners = async () => {
			setIsLoading(true)
			const initialDate = new Date(String(selectedDate.from))
				.toISOString()
				.split('T')[0]

			const finalDate = new Date(String(selectedDate.to))
				.toISOString()
				.split('T')[0]
			try {
				const bannersData = await getPriority(
					destinationLinkSelected.value,
					banner.platform,
					initialDate,
					finalDate,
					customers
				)
				setPriorityNumber(bannersData)
				setIsLoading(false)
			} catch (error) {
				console.error(error)
			}
		}

		handleGetBanners()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedDate, destinationLinkSelected.value, banner.platform])

	React.useEffect(() => {
		const arrayOptions = createArrayWithObjects(priorityNumber)
		setPriorityOptions(arrayOptions)
	}, [priorityNumber])

	return (
		<LayoutModal
			modalTitle="Alterar Banner"
			buttonText="Editar"
			disabledButton={disabledButtonEdit}
			typeLayout="default"
			isOpen={isOpen}
			handleToggleModal={handleToggleModal}
			handleClick={handleEditMOdal}
			height="88%"
			isLoading={isLoading}>
			<EditModalWrapper>
				<MidiaContainer
					deviceSelected={{ value: banner.platform, name: '' }}
					showDeviceSelect={false}
					canDeleteVideo={true}
					hasSelectedVideo={false}
					canDeleteImage={true}
					srcImage={selectedImage}
					showImage={!!selectedImage || !!banner.imageURL}
					handleGetSrcImage={setSelectedImage}
					srcVideo={selectedVideo}
					showVideo={!!selectedVideo || !!banner.videoURL}
					handleGetSrcVideo={setSelectedVideo}
					destinySelected={destinationLinkSelected}
				/>

				<div className="params-wrapper">
					<h2>Opções de programação</h2>
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							gap: '16px'
						}}>
						<FloatLabelLocaleInput
							label="TÍTULO"
							id="title-input"
							{...primaryInputStyle}
							onChange={(event) =>
								setEditTitle(event.target.value)
							}
							style={{
								backgroundColor: 'white',
								color: 'black'
							}}
							value={editTitle}
							maxLength={42}
							labelColor={themeProvider.palette.primary.main}
							primaryColor={colorThemeProvider.lightGrayColor}
						/>
						<DataSelectorList
							selectedOption={destinationLinkSelected}
							title="LINK DESTINO"
							list={destinationsLink}
							isNative={false}
							handleChange={(option) => {
								handleChangeDestinationLinkChange(option.target)
							}}
						/>
						<div
							style={{
								width: '100%',
								display: 'flex',
								gap: '16px'
							}}>
							<DateRangePicker
								selectedDate={selectedDate}
								setSelectedDate={changeSetSelectedDate}
								placeholder="Filtrar por data"
							/>
						</div>
					</div>
				</div>

				<div className="params-wrapper">
					<h2>Comportamento</h2>
					<div
						style={{
							width: '100%',
							display: 'flex',
							flexDirection: 'column',
							gap: '16px'
						}}>
						<DataSelectorList
							selectedOption={actionSelected}
							title="TIPO DE AÇÃO"
							list={actions}
							isNative={false}
							handleChange={(option) =>
								onSelectionChange(option.target)
							}
						/>
						{!isLoading ? (
							<>
								{showSelectProducts && (
									<DataSelectorList
										title={
											actionSelected.value ===
											ActionType.OPEN_PRODUCT
												? 'Selecione a Produto'
												: 'Selecione o Categoria'
										}
										list={productsForDisplay}
										isNative={false}
										selectedOption={
											actionSelected.value ===
											ActionType.OPEN_PRODUCT
												? productSelected
												: categorySelected
										}
										handleChange={(item) => {
											actionSelected.value ===
											ActionType.OPEN_PRODUCT
												? setProductSelected(
														item.target
												  )
												: setCategorySelected(
														item.target
												  )
										}}
									/>
								)}
							</>
						) : (
							<Loading />
						)}
					</div>
				</div>

				<ImpactedClients usersImpacted={banner.amountOfCustomers} />

				<div className="info-modal">
					<InfoOutlinedIcon />
					<p>
						Alteração somente para os clientes do segmento
						selecionado
					</p>
				</div>
			</EditModalWrapper>
		</LayoutModal>
	)
}

export default EditBannerModal
