import { z } from 'zod'
import { verifyFiltersByHieararchy } from './utils/verify-filters-by-hieararchy'
import { verifyBateBeginEnd } from './utils/verify-date-begin-end'

export const createCoupon = () =>
	z
		.object({
			filters: z
				.object({
					filterType: z.enum(['select-pdv', 'filter-hierarchy']),
					listPdvs: z.string().array(),
					filtersByHieararchy: z.object({
						director: z.array(
							z.object({
								label: z.string(),
								value: z.string()
							})
						),
						grc: z.array(
							z.object({
								label: z.string(),
								value: z.string()
							})
						),
						cd: z.array(
							z.object({
								label: z.string(),
								value: z.string()
							})
						),
						state: z.array(
							z.object({
								label: z.string(),
								value: z.string()
							})
						),
						city: z.array(
							z.object({
								label: z.string(),
								value: z.string()
							})
						),
						channel: z.array(
							z.object({
								label: z.string(),
								value: z.string()
							})
						)
					})
				})
				.required()
				.superRefine(
					({ filterType, filtersByHieararchy, listPdvs }, ctx) => {
						if (filterType === 'filter-hierarchy') {
							if (!filtersByHieararchy) return
							verifyFiltersByHieararchy(filtersByHieararchy, ctx)
						}

						if (
							filterType === 'select-pdv' &&
							listPdvs.length === 0
						) {
							ctx.addIssue({
								code: 'custom',
								path: ['filtersByHieararchy'],
								message: 'Upload de planilha é obrigatórios'
							})
						}
					}
				),
			name: z.string().min(1, { message: 'Nome é obrigatório' }).max(16, {
				message: 'Nome deve ter no máximo 16 caracteres'
			}),
			code: z
				.string()
				.min(1, { message: 'Código é obrigatório' })
				.max(14, {
					message: 'Código deve ter no máximo 14 caracteres'
				}),
			date: z
				.object({
					dateStart: z.date({
						errorMap: (issue) => ({
							message:
								issue.code === 'invalid_date'
									? 'Data Inválida'
									: 'Data de inicio de disponibilidade é obrigatório'
						})
					}),
					dateEnd: z.date({
						errorMap: (issue) => ({
							message:
								issue.code === 'invalid_date'
									? 'Data Inválida'
									: 'Data de final de disponibilidade é obrigatório'
						})
					})
				})
				.superRefine(({ dateStart, dateEnd }, ctx) => {
					verifyBateBeginEnd(dateStart, dateEnd, ctx)
				}),
			couponInfo: z
				.object({
					quantityCoupons: z.string(),
					quantityAvailable: z.string()
				})
				.partial()
				.superRefine(({ quantityAvailable, quantityCoupons }, ctx) => {
					if (!quantityCoupons && !quantityAvailable) {
						ctx.addIssue({
							code: 'custom',
							path: ['quantityCoupons'],
							message: 'Quantidade de cupons é obrigatório'
						})
						ctx.addIssue({
							code: 'custom',
							path: ['quantityAvailable'],
							message: 'Quantidade Disponivel é obrigatório'
						})
					}
				}),
			showInApp: z
				.string()
				.min(1, { message: 'Exibido no app é obrigatório' }),
			discountType: z.enum(['money', 'percent'], {
				required_error: 'Tipo de desconto é obrigatório'
			}),
			discountValue: z
				.string()
				.min(1, { message: 'Valor do desconto é obrigatório' }),
			minValueAmount: z
				.string()
				.min(1, { message: 'Valor mínimo de compra é obrigatório' }),
			cumulative: z
				.string()
				.min(1, { message: 'Cumulativo é obrigatório' }),
			type: z.string().min(1, {
				message: 'Tipo de produto é obrigatório'
			}),
			category: z.string().array(),
			brand: z.string().array(),
			package: z.string().array(),
			productsList: z.array(
				z.object({
					label: z.string(),
					options: z.array(
						z.object({
							categoryID: z.string(),
							brand: z.string().optional(),
							label: z.string(),
							package: z.string(),
							value: z.string()
						})
					)
				})
			),
			cupomUsedCount: z.number().default(0),
			tradeStatus: z.string().default('Em análise'),
			status: z.boolean().default(true),
			isPdvRelated: z.boolean().default(false)
		})
		.superRefine(({ type, productsList }, ctx) => {
			const hasNoItemsInProductList =
				productsList.reduce(
					(acc, curr) => (acc += curr.options.length),
					0
				) === 0

			if (type === 'product' && hasNoItemsInProductList) {
				ctx.addIssue({
					code: 'custom',
					path: ['productsList'],
					message: 'A lista de produtos é obrigatória'
				})
			}
		})
export type CreateCouponValidationSchema = z.infer<
	ReturnType<typeof createCoupon>
>
