import React from 'react'
import { InputField, Loading, Table } from '../../../../../shared/components'
import {
	BoxInfo,
	DayLabel,
	DayVertical,
	DaysContainer,
	Row,
	Visit
} from './Information.styles'
import { DividerContent } from '../../consult-client-info.styles'
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined'
import { IInformation } from '../../../index.types'
import { InputStyleSvg } from '../../../consult-client.styles'
import LayoutModal from '../../../../../layout/layout-modal/layout-modal.layout'
import { format } from 'date-fns'

function Information(propsData: any) {
	const defaultProps: IInformation = {
		distributionChannelID: '-',
		customerSegment: '-',
		customerSegmentDescription: '-',
		customerGroupDescription: '-',
		street: '-',
		houseNumber: '-',
		district: '-',
		city: '-',
		region: '-',
		postCode: '-',
		status: '-',
		employeeName: '-',
		countryCode: 'Brasil',
		birthDate: '-',
		PaymentConditionsRef: [
			{
				paymentFormDescription: '-', // Dinheiro ou Boleto
				paymentConditionDescription: '-' // A vista ou 5 dias
			}
		],
		latitude: 0,
		longitude: 0,
		visitFrequence: '-',
		overdueAccountsReceivable: []
	}
	const props: IInformation = {
		...defaultProps,
		...propsData.propsData
	}
	const [isLoading] = React.useState(false)
	const [isModalOpen, setIsModalOpen] = React.useState(false)
	const [inadimplente, setInadimplente] = React.useState(false)
	const daysOfWeek = ['Seg', 'Ter', 'Qua', 'Quin', 'Sex', 'Sab']
	const [selectedDay, setSelectedDay] = React.useState(
		propsData?.propsData?.visitDay
	)

	React.useEffect(() => {
		setSelectedDay(propsData?.propsData?.visitDay)
		setInadimplente(false)
	}, [propsData?.propsData?.visitDay, inadimplente])

	const paramsCondition = props?.PaymentConditionsRef.length > 0
	const inadimplenteCondition = props?.overdueAccountsReceivable.length > 0

	const columns = React.useMemo(
		() => [
			{
				Header: 'Número do Pedido',
				accessor: 'accountingDocumentNumber',
				Cell: ({ value }: any) => <span> #{value}</span>
			},
			{
				Header: 'Valor do boleto',
				accessor: 'amount',
				Cell: ({ value }: any) => (
					<span>
						{' '}
						{value.toLocaleString('pt-br', {
							style: 'currency',
							currency: 'BRL'
						})}
					</span>
				)
			},
			{
				Header: 'Vencimento',
				accessor: 'duedate',
				Cell: ({ value }: any) => (
					<span> {format(new Date(value), 'dd/MM/yy')}</span>
				)
			},
			{
				Header: 'Dias em atraso',
				accessor: 'expiredDays',
				Cell: ({ value }: any) => <span>{value} dias</span>
			}
		],
		[]
	)

	return (
		<>
			{isLoading ? (
				<div className="loading-wrapper">
					<Loading />
				</div>
			) : (
				<>
					<BoxInfo>
						<h1>Informações Gerais</h1>
						<Row>
							<InputField
								className="param-input"
								id="segmento"
								label="Segmento"
								value={`${props?.customerSegment} - ${props?.customerSegmentDescription}`}
								disabled
							/>
							<InputField
								className="param-input"
								id="canal"
								label="Canal"
								value={`${props?.distributionChannelID} - ${props?.customerGroupDescription}`}
								disabled
							/>
							<InputField
								className="param-input"
								id="rede"
								label="Rede"
								value={'-'} // buscar informação
								disabled
							/>
							<InputField
								className="param-input"
								id="bandeira"
								label="Bandeira"
								value={'-'} // buscar informação
								disabled
							/>
						</Row>
						<Row>
							<InputField
								className="param-input"
								id="rua"
								label="Rua"
								value={props?.street}
								disabled
							/>
							<InputField
								className="param-input"
								id="número"
								label="Número"
								value={props?.houseNumber}
								disabled
							/>
							<InputField
								className="param-input"
								id="bairro"
								label="Bairro"
								value={props?.district}
								disabled
							/>
							<InputField
								className="param-input"
								id="cidade"
								label="Cidade"
								value={props?.city}
								disabled
							/>
							<InputField
								className="param-input"
								id="uf"
								label="UF"
								value={props?.region}
								disabled
							/>
						</Row>
						<Row>
							<InputField
								className="param-input"
								id="país"
								label="País"
								value={
									props?.countryCode === 'BR'
										? 'Brasil'
										: props?.countryCode
								}
								disabled
							/>
							<InputField
								className="param-input"
								id="códigoPostal"
								label="Código Postal"
								value={props?.postCode}
								disabled
							/>
							<InputField
								className="param-input"
								id="domicílioFiscal"
								label="Domicílio Fiscal"
								value={'-'} // buscar informação
								disabled
							/>
							<InputField
								className="param-input"
								id="situaçãoCadastral"
								label="Situação Cadastral"
								value={propsData?.others?.status}
								disabled
							/>
							<InputField
								className="param-input"
								id="dataNascimento"
								label="Data de nascimento"
								value={props?.birthDate} // buscar informação
								disabled
							/>

							<InputStyleSvg>
								<InputField
									className="param-input"
									label="Inadimplente"
									value={
										inadimplenteCondition ? 'Sim' : 'Não'
									}
									disabled={!inadimplenteCondition}
								/>
								{inadimplenteCondition && (
									<FindInPageOutlinedIcon
										onClick={() => setIsModalOpen(true)}
									/>
								)}
							</InputStyleSvg>
						</Row>

						<DividerContent />

						<p>Atendimento</p>
						<Row
							style={{
								justifyContent: 'space-between',
								alignItems: 'center'
							}}>
							<Visit>
								<InputField
									className="param-input"
									id="vendedor"
									label="Vendedor"
									value={propsData?.others?.sellerName}
									disabled
								/>
								<InputField
									className="param-input"
									id="frequenciaVisitas"
									label="Frequência de visitas"
									value={
										props?.visitFrequence === '10'
											? 'Semanal'
											: '15'
											? 'Quinzenal'
											: '20'
											? 'Mensal'
											: '-'
									}
									disabled
								/>
							</Visit>

							<DaysContainer>
								<p>Dias de atendimento</p>
								<DayVertical>
									{daysOfWeek.map((day) => (
										<DayLabel key={day}>
											{day}
											<input
												type="checkbox"
												value={day}
												checked={selectedDay === day}
												disabled
											/>
										</DayLabel>
									))}
								</DayVertical>
							</DaysContainer>
						</Row>

						<DividerContent />

						<p>Formas de pagamento cadastrados</p>
						<Row>
							<InputField
								className="param-input"
								id="parâmetro"
								label="Parâmetro pagamento online"
								value={paramsCondition ? 'Sim' : 'Não'}
								disabled
							/>
						</Row>
						{paramsCondition && (
							<Row>
								{props?.PaymentConditionsRef.map(
									(i: any, key) => (
										<InputField
											className="param-input"
											id="boleto"
											key={key}
											label={
												i.paymentConditionDescription
											}
											value={i.paymentFormDescription}
											disabled
										/>
									)
								)}
							</Row>
						)}

						<DividerContent />

						<p>Localização</p>
						<Row>
							<InputField
								className="param-input"
								id="latitude"
								label="Latitude"
								value={props?.latitude}
								disabled
							/>
							<InputField
								className="param-input"
								id="longitude"
								label="Longitude"
								value={props?.longitude}
								disabled
							/>
						</Row>
					</BoxInfo>

					<LayoutModal
						isOpen={isModalOpen}
						handleToggleModal={() => {
							setIsModalOpen(false)
						}}
						height={'659px'}
						width={'648px'}
						modalTitle={'Detalhes de inadimplência'}
						buttonText="Voltar"
						typeLayout="details"
						handleClick={() => {
							setIsModalOpen(false)
						}}>
						<>
							<Table
								data={props?.overdueAccountsReceivable}
								columns={columns as any}
							/>
						</>
					</LayoutModal>
				</>
			)}
		</>
	)
}

export default Information
