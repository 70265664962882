import { IOption } from '../../../../../components/filters-by-hieararchy/typings'
import { currencyMask } from '../../../../../utils/currency-mask'
import { UNLIMITED_VALUE_COUPON } from '../../constants/unlimited-value-coupon'
import { CreateCouponValidationSchema } from '../../schemas/create-coupon'
import { formatCurrencyToNumber } from '../../utils/format-currency-to-number'
import { formatDiscountCategories } from '../../utils/format-discountCategories'
import { ExtendedCreateCouponValidationSchema } from '../coupon.service'
import {
	CreateCoupon,
	DiscountType,
	EditCouponValidationSchema,
	ICouponResponseProps,
	IDiscountItems,
	IProduct
} from '../types/coupon.types'

class CouponMapper {
	toPersistence(
		domain: CreateCouponValidationSchema,
		userImpacted: string
	): CreateCoupon {
		return {
			filters: {
				director: domain.filters.filtersByHieararchy.director.map(
					(item) => item.value
				),
				grc: domain.filters.filtersByHieararchy.grc.map(
					(item) => item.value
				),
				cd: domain.filters.filtersByHieararchy.cd.map(
					(item) => item.value
				),
				state: domain.filters.filtersByHieararchy.state.map(
					(item) => item.value
				),
				city: domain.filters.filtersByHieararchy.city.map(
					(item) => item.value
				),
				channel: domain.filters.filtersByHieararchy.channel.map(
					(item) => item.value
				),
				codePdv:
					domain.filters.filterType === 'select-pdv'
						? domain.filters.listPdvs.map((item) => item)
						: []
			},
			coupon: {
				name: domain.name,
				code: domain.code,
				couponType: domain.type === 'cart' ? 'cart' : 'item',
				minOrderValue: formatCurrencyToNumber(domain.minValueAmount),
				discountType: domain.discountType,
				discountValue:
					domain.discountType === 'money'
						? formatCurrencyToNumber(domain.discountValue)
						: Number(domain.discountValue),
				cumulative: domain.cumulative === 'true',
				dateStart: domain.date.dateStart.toISOString(),
				dateEnd: domain.date.dateEnd.toISOString(),
				discountCategory: formatDiscountCategories({
					categoires: domain.category,
					couponType: domain.type
				}),
				quantityAvailable:
					domain.couponInfo.quantityAvailable === 'Ilimitado'
						? UNLIMITED_VALUE_COUPON
						: Number(domain.couponInfo?.quantityCoupons ?? 1),
				showIn: domain.showInApp !== 'none' ? 'APP' : 'NONE',
				discountItems: domain.productsList
					.flatMap((product) => product.options)
					.map((item) => ({
						description: item.label,
						materialID: item.value,
						category: item.categoryID,
						brand: item?.brand ?? '',
						package: item.package
					})),
				cupomUsedCount: 0,
				status: true,
				tradeStatus: 'Em análise',
				isPdvRelated:
					domain.filters.filterType === 'select-pdv' ? true : false,
				amountOfCustomers:
					domain.filters.filterType === 'select-pdv'
						? String(
								domain.filters.listPdvs.map((item) => item)
									.length
						  )
						: userImpacted
			}
		}
	}
	toOption(name: string): IOption {
		return {
			label: name,
			value: name
		}
	}
	toProductfunction(discountItems: IDiscountItems[] | undefined): IProduct[] {
		const groupedItems: { [category: string]: IDiscountItems[] } = {}

		// Agrupar os itens pelo campo 'category'
		discountItems?.forEach((item) => {
			if (!groupedItems[item.category]) {
				groupedItems[item.category] = []
			}
			groupedItems[item.category].push(item)
		})

		// Transformar os itens agrupados no formato desejado
		const products: IProduct[] = Object.keys(groupedItems).map(
			(category) => {
				return {
					label: category,
					options: groupedItems[category].map((item) => {
						return {
							value: item.materialID,
							label: item.description,
							package: item.package,
							categoryID: item.category,
							brand: item.brand
						}
					})
				}
			}
		)

		return products
	}
	toFiltersObject(inputString: string) {
		// Removendo as barras invertidas da string
		const jsonString: string = inputString.replace(/\\/g, '')
		// Convertendo a string JSON para objeto JavaScript
		const parsedObject = JSON.parse(jsonString)

		const formatArray = (
			arr: string[]
		): { label: string; value: string }[] => {
			return arr.map((value) => ({ label: value, value: value }))
		}

		// Criando o objeto no formato desejado
		const transformedObject = {
			director: formatArray(parsedObject.director || []),
			grc: formatArray(parsedObject.grc || []),
			cd: formatArray(parsedObject.cd || []),
			city: formatArray(parsedObject.city || []),
			state: formatArray(parsedObject.state || []),
			channel: formatArray(parsedObject.channel || []),
			codePdv: parsedObject.codePdv || []
		}

		return transformedObject
	}
	toDomain(
		persistence: ICouponResponseProps
	): ExtendedCreateCouponValidationSchema & { amountOfCustomers: string } {
		return {
			ID: persistence.ID,
			code: persistence.code,
			type: persistence.couponType === 'item' ? 'product' : 'cart',
			date: {
				dateStart: new Date(persistence.dateStart),
				dateEnd: new Date(persistence.dateEnd)
			},
			filters: {
				filtersByHieararchy: this.toFiltersObject(
					String(persistence.filters)
				),
				filterType: persistence.isPdvRelated
					? 'select-pdv'
					: 'filter-hierarchy',
				listPdvs: this.toFiltersObject(String(persistence.filters))
					.codePdv
			},
			category: [],
			brand: [],
			package: [],
			couponInfo: {
				quantityAvailable:
					persistence.quantityAvailable === UNLIMITED_VALUE_COUPON
						? 'Ilimitado'
						: undefined,
				quantityCoupons:
					persistence.quantityAvailable === UNLIMITED_VALUE_COUPON
						? undefined
						: String(persistence.quantityAvailable)
			},
			cumulative: String(persistence.cumulative),
			discountType: persistence.discountType ?? 'percent',
			discountValue: this.toFormatCurrencyValue(
				persistence.discountType,
				persistence.discountValue
			),
			minValueAmount: this.toFormatCurrencyValue(
				persistence.discountType,
				persistence.minOrderValue
			),
			name: persistence.name,
			productsList: this.toProductfunction(persistence.discount_items),
			showInApp: persistence.showIn === 'APP' ? 'app' : 'none',
			cupomUsedCount: persistence.cupomUsedCount,
			status: persistence.status,
			tradeStatus: 'Em análise',
			isPdvRelated: persistence.isPdvRelated ? true : false,
			amountOfCustomers: persistence.amountOfCustomers
		}
	}
	toFormatCurrencyValue(
		discountType: ICouponResponseProps['discountType'],
		discountValue: ICouponResponseProps['discountValue']
	) {
		if (discountType === DiscountType.MONEY) {
			return currencyMask(Number(discountValue).toFixed(2))
		}

		return String(discountValue)
	}
	toPersistenceEdit(
		domain: CreateCouponValidationSchema
	): EditCouponValidationSchema {
		return {
			name: domain.name,
			cumulative: Boolean(domain.cumulative),
			dateStart: domain.date.dateStart,
			dateEnd: domain.date.dateEnd,
			discountValue:
				domain.discountType === 'money'
					? formatCurrencyToNumber(domain.discountValue)
					: Number(domain.discountValue),
			minOrderValue: formatCurrencyToNumber(domain.minValueAmount),
			quantityAvailable:
				domain.couponInfo.quantityAvailable === 'Ilimitado'
					? UNLIMITED_VALUE_COUPON
					: Number(domain.couponInfo?.quantityCoupons ?? 1)
		}
	}
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CouponMapper()
