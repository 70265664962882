import { initializeApp } from "firebase/app";
import { getRemoteConfig } from "firebase/remote-config";


const firebaseConfig = {
  apiKey: "AIzaSyAFt4u9fjeHJikZ0-HaKYjq28lJyTAR-hk",
  authDomain: "bom-parceiro-mobile-app-gp.firebaseapp.com",
  projectId: "bom-parceiro-mobile-app-gp",
  storageBucket: "bom-parceiro-mobile-app-gp.appspot.com",
  messagingSenderId: "24599302065",
  appId: "1:24599302065:web:57e5505ea4a76e8143f790",
  measurementId: "G-NGQBMFLHVD"
};


const firebase = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(firebase);
const firebaseObject = {
  firebase,
  remoteConfig
}

export default firebaseObject