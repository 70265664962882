import { IProduct } from './types'

export const DEFAULT_PRODUCT: IProduct = {
	ID: '',
	MaterialID: '',
	Description: '',
	UnitMeasureSale: '',
	SiteDescription: '',
	ImageUrl: '',
	PackageImageUrl: '',
	Category: '',
	Brand: '',
	Package: '',
	PackageType: '',
	Family: '',
	Style: '',
	Coloration: '',
	AlcoholContent: '',
	UnitBarcode: '',
	PackageBarcode: '',
	IBU: '',
	LogicalDelete: '',
	ScoreValue: '',
	ScoreRate: '',
	categoryId: '',
	listingPosition: null
}

export const DEFAULT_PROD_IMAGE = {
	unitImage: {
		file: '',
		name: '',
		preview: ''
	},
	packImage: {
		file: '',
		name: '',
		preview: ''
	}
} as const

export const MOCK_PRODUCT: IProduct = {
	MaterialID: '0123456789',
	Description: 'teste',
	UnitMeasureSale: 'UN',
	SiteDescription: 'teste',
	ImageUrl: '',
	PackageImageUrl: '',
	Category: 'Cerveja',
	Brand: 'TesteInc',
	Package: 'Lata 269ml',
	PackageType: 'Descartável',
	Family: 'Lager',
	Style: 'Diferente',
	Coloration: 'Azul',
	AlcoholContent: '5,00%',
	UnitBarcode: '0123456789',
	PackageBarcode: '9876543210',
	IBU: '5',
	LogicalDelete: '',
	ScoreValue: '1:1',
	ScoreRate: '1000'
}
